<template>
    <div class="c-app flex-row align-items-center">
        <CContainer>
            <CRow class="justify-content-center">
                <CCol md="6">
                    <CCardGroup>
                        <CCard class="p-4">
                            <CCardBody>
                                <CForm>
                                    <h1>ログイン</h1>
                                    <p class="text-muted">訪問看護日報管理システム</p>
                                    <CInput
                                        placeholder="アカウント名"
                                        autocomplete="username email"
                                        v-model="form.login_name"
                                    >
                                        <template #prepend-content>
                                            <CIcon name="cil-user"/>
                                        </template>
                                    </CInput>
                                    <CInput
                                        placeholder="パスワード"
                                        type="password"
                                        autocomplete="curent-password"
                                        v-model="form.password"
                                    >
                                        <template #prepend-content>
                                            <CIcon name="cil-lock-locked"/>
                                        </template>
                                    </CInput>
                                    <CRow>
                                        <CCol col="6" class="text-left">
                                            <CButton color="primary" class="px-4" @click="login">ログイン</CButton>
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </CCardBody>
                        </CCard>
                    </CCardGroup>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>

<script>
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: 'Login',
    mixins: [ErrorHandler],
    data: function () {
        return {
            form: {
                login_name: '',
                password: ''
            }
        }
    },
    methods: {
        login: function () {
            window.axios.post('/login', {
                login_name: this.form.login_name,
                password: this.form.password
            })
                .then((response) => {
                    window.location.href = '/daily-report-select';
                })
                .catch((error) => {
                    this.handleErrorStatusCode(error);
                });
        }
    }
}
</script>
